<template>
  <el-form
      ref="form"
      :model="form"
      label-width="120px"
      :rules="rules"
      v-if="form"
  >
    <el-form-item label="联系人：" prop="jobApplyDetailInDTO.linkMan">
      <el-input
          placeholder="请输入联系人"
          v-model="form.jobApplyDetailInDTO.linkMan"
          class="width90"
      ></el-input>
    </el-form-item>
    <el-form-item label="联系电话：" prop="jobApplyDetailInDTO.linkTel">
      <el-input
          placeholder="请输入联系电话"
          v-model.trim="form.jobApplyDetailInDTO.linkTel"
          class="width90"
      ></el-input>
    </el-form-item>
    <el-form-item label="面试职位：" prop="jobApplyDetailInDTO.jobId">
      <el-select
          v-model="form.jobId"
          placeholder="请选择面试职位"
          class="width90"
          clearable
      >
        <el-option
            v-for="(item, index) in jobNameList"
            :label="item.jobName"
            :value="item.id"
            :key="index"
        >{{ item.jobName }}
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="面试时间：" prop="jobApplyDetailInDTO.interviewTime">
      <el-date-picker
          v-model="form.jobApplyDetailInDTO.interviewTime"
          type="datetime"
          class="width90"
          value-format="yyyy/MM/dd HH:mm:ss"
          default-time="09:00:00"
          :disabledDate="true"
          placeholder="选择日期"
          @change="saveTime"
          :picker-options="pickerOptions"
          popper-class="eagle-a11y-uncut"
      ></el-date-picker>
    </el-form-item>
    <el-form-item label="面试地点：" v-if="isSp">
      <ThreeLink
          class="width90"
          placeholder="请选择工作地址"
          :codeType="'provinceid'"
          :codeLevel="'-1'"
          @setMValue="
          setScasValue($event, form.jobApplyDetailInDTO, [
            'provinceid',
            'cityid',
            'threeCityid',
          ])
        "
          :defaultValue="[
          form.jobApplyDetailInDTO.provinceid,
          form.jobApplyDetailInDTO.cityid,
          form.jobApplyDetailInDTO.threeCityid,
        ]"
      ></ThreeLink>
    </el-form-item>
    <el-form-item
        label="面试地点："
        prop="jobApplyDetailInDTO.provinceid"
        v-else
    >
      <ThreeLink
          class="width90"
          placeholder="请选择工作地址"
          :codeType="'provinceid'"
          :codeLevel="'-1'"
          @setMValue="
          setScasValue($event, form.jobApplyDetailInDTO, [
            'provinceid',
            'cityid',
            'threeCityid',
          ])
        "
          :defaultValue="[
          form.jobApplyDetailInDTO.provinceid,
          form.jobApplyDetailInDTO.cityid,
          form.jobApplyDetailInDTO.threeCityid,
        ]"
      ></ThreeLink>
    </el-form-item>
    <el-form-item class="checked" v-if="locationCity != '河南省'">
      <el-checkbox v-model="isSp" @change="checkHandle">远程面试
      </el-checkbox
      >
      <span>（可通过电话或其他途径进行远程沟通面试）</span>
    </el-form-item>
    <el-form-item label="详细地址：" v-if="isSp">
      <el-input
          placeholder="请输入详细地址"
          v-model="form.jobApplyDetailInDTO.address"
          class="width90"
      ></el-input>
    </el-form-item>
    <el-form-item label="详细地址：" prop="jobApplyDetailInDTO.address" v-else>
      <el-input
          placeholder="请输入详细地址"
          v-model="form.jobApplyDetailInDTO.address"
          class="width90"
      ></el-input>
    </el-form-item>
    <el-form-item label="面试备注：" class="text_L">
      <Editor
          placeholder="请输入内容"
          @html="applyMessa"
          class="h250 width90"
      ></Editor>
    </el-form-item>

    <div class="buttons text_C autos">
      <el-button
          type="primary"
          class="w170 bs_btn_oo"
          style="margin-left:0px"
          @click="applyUpdate('form')"
          v-if="isShow"
      >邀请面试
      </el-button>
    </div>
  </el-form>
</template>

<script>
import Editor from "../public/editor";
import ThreeLink from "../selectComponents/ThreeLink";

export default {
  // inject: ["reload"],
  props: ["invativeId", "jobInfo", "time"],
  components: {
    ThreeLink,
    Editor,
  },
  data() {
    return {
      content: "",
      isSp: false,
      isShow: true,
      pickerOptions: {
        disabledDate(time) {
          return Date.now()-(60*60*24*1000) > time.getTime();
        },
      },
      form: {
        jobId: "",
        id: "",
        memberIds: [],
        applyStatus: "4",
        jobApplyDetailInDTO: {
          threeCityid: "",
          address: "",
          linkMan: "",
          linkTel: "",
          interviewTime: "",
          applyMessage: "",
          provinceid: "",
          cityid: "",
          isSp: "0",
          jobId: '',
        },
        memberId: "",
      },
      saveTimes: "",
      rules: {
        jobApplyDetailInDTO: {
          linkMan: [
            {
              required: true,
              message: "请填写企业联系人姓名",
              trigger: "change",
            },
          ],
          address: [
            {
              required: true,
              message: "请填写详细地址",
              trigger: "change,blur",
            },
          ],
          // isSp: [
          //   {
          //     required: false,
          //     message: "",
          //     trigger: "change,blur",
          //   },
          // ],
          linkTel: [
            {
              required: true,
              message: "请填写联系方式，很重要哦！",
              trigger: "change,blur",
            },
            {
              pattern: /^1[3456789]\d{9}$/,
              message: "电话号码格式不对",
              trigger: "blur",
            },
          ],
          jobId: [
            {
              required: true,
              message: "请选择面试岗位",
              trigger: "change,blur",
            },
          ],
          interviewTime: [
            {
              required: true,
              message: "请选择面试时间",
              trigger: "change,blur",
            },
          ],

          provinceid: [
            {required: true, message: "请选择地址", trigger: "change,blur"},
          ],

          applyMessage: [
            {
              required: true,
              message: "请填写面试备注",
              trigger: "change,blur",
            },
          ],
        },
      },

      value: [],
      options: [],
      jobNameList: [],
      disabled: false,
      locationCity: "",
    };
  },
  methods: {
    checkHandle(val) {
      if (val == true) {
        this.form.jobApplyDetailInDTO.isSp = "1";
      } else {
        this.form.jobApplyDetailInDTO.isSp = "0";
      }
    },
    // 提交表单接口
    applyUpdate(form) {
      console.log(this.form.jobApplyDetailInDTO);
      this.form.jobApplyDetailInDTO.jobId = this.form.jobApplyDetailInDTO.jobId?this.form.jobApplyDetailInDTO.jobId:this.form.jobId;
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let res = await this.$api.applyFacing(this.form);
          if (res.data.success) {
            this.$message({
              message: "邀请发送成功！",
              type: "success",
            });
            this.form = {};
            this.$parent.close();
            this.$emit("close", false);
            this.time = 3;
            if (this.jobInfo) {
              localStorage.setItem("id", this.jobInfo.memberId);
            }
            // this.reload();
          }
        } else {
          this.$message({
            message: "信息项未填写完整！",
            type: "warning",
          });
          console.log("信息项不全!!");
          return false;
        }
      });
    },
    async getJobName() {
      let that = this;
      let comid = this.$store.state.login.userInfo.id;
      let res = await that.$axios.get(
          "/api/app-jycy-job/getJobNameListByCompanyId"
      );
      this.jobNameList = res.data.data;
      if (this.jobNameList == null || this.jobNameList.length <= 0) {
        this.$message.warning("当前企业没有在招职位！");
        this.$emit("close", false);
        return;
      }
      this.total = that.jobNameList.length;
    },
    applyMessa(val) {
      this.form.jobApplyDetailInDTO.applyMessage = val;
    },
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    async getCompanyInfo() {
      let that = this;
      let res = await that.$axios.get(
          "/api/app-jycy-company/com/getCompanyInfo"
      );
      if (res.data.success) {
        let data = res.data.data;
        that.form.jobApplyDetailInDTO.linkMan = data.linkman;
        that.form.jobApplyDetailInDTO.linkTel = data.linkphone.replace(/\s*/g,"");
        that.form.jobApplyDetailInDTO.provinceid = data.provinceidValue;
        that.form.jobApplyDetailInDTO.cityid = data.cityidValue;
        that.form.jobApplyDetailInDTO.threeCityid = data.threeCityValue;
        that.form.jobApplyDetailInDTO.address = data.address;
        that.form.jobApplyDetailInDTO.isSp = data.isSp;
      }
    },
    saveTime(val) {
      // if (val) {
      //   localStorage.setItem("time", Date.parse(val));
      // }
    },
  },
  created() {
    this.form.memberIds.push(this.$store.state.recruit.memberId || this.jobInfo.memberId);
    this.locationCity = localStorage.getItem("locationSite");
    if (this.jobInfo.id) {
      this.jobNameList = [
        {
          id: this.jobInfo.id,
          jobName: this.jobInfo.jobName,
        },
      ];
      this.form.jobId = this.jobInfo.id;
      this.form.id = this.jobInfo.iddd;
      this.disabled = true;
    } else {
      this.form.id = this.jobInfo.iddd;
      this.getJobName();
    }
    //最近一条面试邀请信息
    // this.interviewInfo();
    //  信息回显
    this.getCompanyInfo();
  },
};
</script>

<style lang="less" scoped>
.bg {
  top: -80px;
}

/deep/ .el-form-item__content {
  margin-left: 0px;

  .el-input {
    width: 100%;
    height: 100%;
  }
}

.autos {
  display: flex;
  margin-left: 0px;
  justify-content: center;

  .el-button {
    margin-left: 0px;
  }
}

/deep/ .el-dialog__title {
  color: orange;
}

/deep/ .el-input__inner {
  height: 34px;
}

.checked {
  span {
    color: #999999;
  }

  .el-form-item__content {
    margin-left: 40px;
  }
}
</style>
