<template>
  <div class="resume">
    <div
      class="loads"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
    >
      <h1>我的简历</h1>
      <div class="resume_content">
        <section class="header flex" v-if="disabledOutDTO">
          <div class="imgs">
            <img :src="disabledOutDTO.imageurl" alt class="avatar err_avatar" />
          </div>
          <ul class="info">
            <li class="flex">
              <h2>
                {{ disabledOutDTO.userName }}
                <small>{{ disabledOutDTO.jobStatus }}</small>
              </h2>
            </li>
            <li class="edu">
              <span>
                {{ disabledOutDTO.sex }}
                <span>/</span>
              </span>
              <span v-if="disabledOutDTO.age">
                {{ disabledOutDTO.age }}岁
              </span>
              <span v-if="disabledOutDTO.edu"
                ><span>/</span>{{ disabledOutDTO.edu }}</span
              >
              <span v-if="disabledOutDTO.exp"
                ><span>/</span>{{ disabledOutDTO.exp }}</span
              >
            </li>
            <li
              class="level"
              v-for="(item, index) in disabledOutDTO.disabledType"
              :key="index"
            >
              <span>
                {{ item.disabledType }}
                <span>/</span>
              </span>
              <span>
                {{ item.disabledLevel }}
                <span>/</span>
              </span>
              <span>{{ item.disabledDetail }}</span>
            </li>
            <li class="link">
              <span v-if="disabledOutDTO.linkPhone">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="想和求职者取得联系吗？您可通过发送邀请面试哦，求职者看到您的邀请后会与您联系！"
                  placement="top-start"
                >
                  <div class="div">
                    <img src="~/static/center/phone.svg" alt />
                    {{ disabledOutDTO.linkPhone }}
                  </div>
                </el-tooltip>
              </span>
              <span v-if="disabledOutDTO.linkEmail">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="想和求职者取得联系吗？您可通过发送邀请面试哦，求职者看到您的邀请后会与您联系！"
                  placement="top-start"
                >
                  <div class="div">
                    <img src="~/static/center/email.svg" alt />
                    {{ disabledOutDTO.linkEmail }}
                  </div>
                </el-tooltip>
              </span>
              <el-button
                type="primary"
                @click="isShow = true"
                v-if="userType == 2"
                >邀请面试</el-button
              >
            </li>
          </ul>
        </section>
        <section class="intention">
          <div class="title">
            <h2>求职意向</h2>
          </div>
          <ul class="intent">
            <li>
              <p>
                求职状态：
                <span>{{ resumeExpectOutDTO.jobStatus }}</span>
              </p>
              <p>
                期望行业：
                <span>{{ resumeExpectOutDTO.jobTop }}</span>
              </p>

              <p>
                期望职业：
                <span>{{ resumeExpectOutDTO.jobPost }}</span>
              </p>
            </li>
            <li>
              <p>
                工作性质：
                <span>{{ resumeExpectOutDTO.jobType }}</span>
              </p>
              <p>
                期望地点：
                <span>{{ resumeExpectOutDTO.provinceid }}</span>
              </p>
              <p>
                税前月薪：
                <span>{{ resumeExpectOutDTO.salary }}</span>
              </p>
            </li>
          </ul>
        </section>
        <section class="experience">
          <div class="title">
            <h2>工作经验</h2>
          </div>
          <div v-for="(item, index) in resumeWorkOutDTOS" :key="index">
            <div class="info">
              <h2 class="relative">
                {{ item.companyName }}
                <small class="time"
                  >{{ item.beginTime }}~{{ item.endTime }}</small
                >
              </h2>
              <h3>{{ item.jobName }}</h3>
              <p class="p1">
                <span>所属部门：{{ item.department }}</span>
                <span>所属行业：{{ item.hyTop }}</span>
              </p>
              <p
                class="p2"
                v-for="(lables, index) in item.skillBar"
                :key="index"
              >
                技能标签：
                <span>JavaScript</span>
                <span>nodeJs</span>
                <span>vue</span>
              </p>
              <h4>职位描述</h4>
              <div class="text" v-html="item.jobContent"></div>
            </div>
          </div>
        </section>
        <section class="education">
          <div class="title">
            <h2>教育经历</h2>
          </div>
          <div v-for="(item, index) in resumeEduOutDTOS" :key="index">
            <div class="info relative">
              <p>
                <span>{{ item.schoolName }}</span>
                <span>{{ item.majorName }}</span>
                <span>{{ item.edu }}</span>
                <span>{{ item.benginTime }}-{{ item.endTime }}</span>
              </p>
              <div class="text">
                <h4>在校经历：</h4>
                <p v-html="item.schoolExperience"></p>
              </div>
            </div>
          </div>
        </section>
        <section class="education train">
          <div class="title">
            <h2>培训经历</h2>
          </div>
          <div v-for="(item, index) in resumeTrainOutDTOS" :key="index">
            <div class="info relative">
              <p>
                <span>{{ item.trainOrganization }}</span>
                <span>{{ item.trainCourse }}</span>
                <span>{{ item.beginTime }}-{{ item.endTime }}</span>
              </p>
              <h4>培训内容</h4>
              <div class="text marT20" v-html="item.trainContent"></div>
            </div>
          </div>
        </section>
        <section class="education skill">
          <div class="title">
            <h2>专业技能</h2>
          </div>
          <div v-for="(item, index) in resumeSkillOutDTOS" :key="index">
            <div class="info relative">
              <p>
                <span>{{ item.name }}</span>
                <span>{{ item.ing }}</span>
              </p>
            </div>
          </div>
        </section>
        <section class="education certificate">
          <div class="title">
            <h2>持有证书</h2>
          </div>
          <div v-for="(item, index) in resumeCertOutDTOS" :key="index">
            <div class="info relative">
              <p>
                <span>{{ item.certName }}</span>
                <span>{{ item.certTime }}</span>
              </p>
            </div>
          </div>
        </section>
        <section class="education language">
          <div class="title">
            <h2>语言能力</h2>
          </div>
          <div v-for="(item, index) in resumeLanguageOutDTOS" :key="index">
            <div class="info relative">
              <p>
                <span>{{ item.language }}</span>
                <span>
                  听说能力
                  <i></i>
                  {{ item.lsAbility }}
                </span>
                <span>
                  读写能力
                  <i></i>
                  {{ item.swAbility }}
                </span>
              </p>
            </div>
          </div>
        </section>
        <section class="experience project">
          <div class="title">
            <h2>项目经验</h2>
          </div>
          <div v-for="(item, index) in resumeProjectOutDTOS" :key="index">
            <div class="info">
              <h2 class="relative">
                {{ item.projectName }}
                <small class="time"
                  >{{ item.benginDate }}~{{ item.endDate }}</small
                >
              </h2>
              <h3>项目描述</h3>
              <div class="text" v-html="item.projectDescript"></div>
            </div>
          </div>
        </section>
        <section class="personal">
          <div class="title">
            <h2>自我评价</h2>
          </div>
          <div
            class="text"
            v-html="resumeDescriptionOutDTO.description"
            v-if="resumeDescriptionOutDTO"
          ></div>
        </section>
      </div>
    </div>
    <el-dialog title="面试邀请" width="40%" :visible.sync="isShow" class="bg">
      <Invative
        v-show="isShow"
        @close="close"
        :jobInfo="{ memberId: $route.query.id }"
      ></Invative>
    </el-dialog>
  </div>
</template>

<script>
import Invative from "@/components/enterprise/Invative";
export default {
  name: "resume",
  components: {
    Invative,
  },
  data() {
    return {
      loading: false,
      imageurl: "",
      resumesList: [],
      disabledOutDTO: [],
      resumeExpectOutDTO: [],
      resumeDescriptionOutDTO: {},
      resumeWorkOutDTOS: [],
      resumeEduOutDTOS: [],
      resumeTrainOutDTOS: [],
      resumeSkillOutDTOS: [],
      resumeCertOutDTOS: [],
      resumeLanguageOutDTOS: [],
      resumeProjectOutDTOS: [],
      userType: "",
      isShow: false,
      otherId: "",
    };
  },
  methods: {
    // 基本信息
    disableResume() {
      let params = {
        memberId: this.$route.query.id,
        userType: this.$route.query.userType,
        otherId: this.$route.query.otherId,
      };
      this.loading = true;
      this.$api
        .disableResume(params)
        .then((res) => {
          if (res.data.success) {
			console.log(this.$route.query);
            this.resumesList = res.data.data;
            this.disabledOutDTO = this.resumesList.disabledOutDTO;
            document.title =
              this.disabledOutDTO.userName +
                "-兰州市残疾人就业创业网络服务平台——中国残联官方残疾人求职网站" ||
              "个人简历";
            this.resumeExpectOutDTO = this.resumesList.resumeExpectOutDTO;
            this.resumeDescriptionOutDTO =
              this.resumesList.resumeDescriptionOutDTO;
            this.resumeWorkOutDTOS = this.resumesList.resumeWorkOutDTOS;
            this.resumeEduOutDTOS = this.resumesList.resumeEduOutDTOS;
            this.resumeTrainOutDTOS = this.resumesList.resumeTrainOutDTOS;
            this.resumeSkillOutDTOS = this.resumesList.resumeSkillOutDTOS;
            this.resumeCertOutDTOS = this.resumesList.resumeCertOutDTOS;
            this.resumeLanguageOutDTOS = this.resumesList.resumeLanguageOutDTOS;
            this.resumeProjectOutDTOS = this.resumesList.resumeProjectOutDTOS;
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          console.log("错误了", error);
        });
    },
    // 关闭
    close() {
      this.isShow = false;
    },
  },
  created() {
    this.disableResume(this.$route.query.id);
    this.userType = this.$route.query.userType;
    console.log(
      this.$store.state.login.userInfo.id,
      this.$route.query.otherId,
      "dsa"
    );
    if (this.$store) {
    }
  },
};
</script>

<style scoped lang="less">
.resume {
  width: 1000px;
  margin: 20px auto;
  padding: 50px 30px;
  background: white;
  h1 {
    margin-bottom: 30px;
  }
  section {
    padding: 20px 0;
    margin: 0 20px;
    border-bottom: 1px dashed #e1e1e1;
    .title {
      position: relative;
      padding-bottom: 10px;
      h2 {
        font-size: 18px;
      }
    }
    p.p_list {
      span {
        position: relative;
        color: #666666;
        padding-right: 20px;
      }
      span::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: 1px;
        height: 14px;
        background: #666666;
      }
      span:last-child::after {
        display: none;
      }
    }
  }
  section:last-child {
    border: none;
  }
  .resume_content {
    flex: 1;
    .header {
      .avatar {
        width: 80px;
        height: 80px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        margin-right: 20px;
        vertical-align: top;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info {
        display: inline-block;
        li {
          padding-top: 10px;
        }
        li {
          h2 {
            font-size: 20px;
            margin-right: 10px;
          }
          .select {
            width: 136px;
            margin-right: 10px;
          }
          .edit {
            cursor: pointer;
            img {
              margin-right: 5px;
            }
            color: #00924c;
          }
        }
        .edu {
          padding-top: 5px;
          span {
            span {
              color: #999999;
              padding: 0 10px;
            }
          }
        }
        .level {
          padding-top: 5px;
          color: #666666;
          font-size: 12px;
          span {
            padding: 0 2px;
          }
        }
        .link {
          span {
            display: inline-block;
            margin-right: 40px;
            color: #666666;
            cursor: pointer;
            img {
              width: 24px;
              vertical-align: middle;
              transform: translateY(-1px);
            }
          }
        }
      }
    }
    // //残疾情况
    // .situation {
    // }
    //求职意向
    .intention {
      ul {
        display: flex;
        width: 70%;
        justify-content: space-between;
        li {
          line-height: 2.5em;

          span {
            color: #666666;
            text-align: left;
          }
        }
      }
    }
    //工作经验
    .experience {
      .info {
        h2 {
          font-size: 16px;
          padding: 10px 0;
          small {
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            padding-left: 10px;
          }
          p {
            position: absolute;
            top: 10px;
            right: 0;
            color: #666666;
            font-weight: 400;
            cursor: pointer;
            span {
              padding-left: 10px;
              font-size: 14px;
              color: #00924c;
              i {
                font-size: 16px;
                margin-right: 5px;
              }
            }
            span:first-child {
              padding-right: 30px;
            }
          }
        }
        h3 {
          font-size: 14px;
          padding-bottom: 5px;
        }
        h4 {
          margin-top: 10px;
        }
        .p1 {
          font-weight: 400;
          span {
            padding-right: 50px;
          }
        }
        .p2 {
          padding: 20px 0;
          span {
            display: inline-block;
            font-size: 12px;
            padding: 0 10px;
            border: 1px solid #e1e1e1;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            color: #666666;
            margin-right: 5px;
          }
        }
      }
    }
    //  教育经历
    .education {
      .info {
        margin-top: 5px;
        border-bottom: 1px dotted #dfdfdf;
        padding-bottom: 5px;
        color: #666666;
        span {
          padding-right: 50px;
          i {
            padding-right: 10px;
          }
        }
      }
      .info:last-child {
        border: none;
      }
      h4 {
        margin-top: 5px;
        font-weight: 500;
        margin-bottom: 3px;
      }
      .clicks {
        position: absolute;
        top: 0;
        right: 0;
        .edit {
          padding-right: 30px;
          cursor: pointer;
          i {
            padding-right: 5px;
          }
          color: #00924c;
        }
        .edit:last-child {
          padding-right: 0;
        }
      }
    }
    .train {
      h4 {
        font-weight: bold;
        color: #000000;
      }
      .text {
        margin-top: 0px;
      }
    }
  }
}
</style>
